/** @format */

import { useEffect, useState } from "react";

import Scripts from "../utils/Scripts";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "../pages/slider/slider";

import Qrcode from "../img/qrcode.png";

import Select from "react-select";
import { MdVerifiedUser } from "react-icons/md";
import DonationsAnimated from "../utils/DonationsAnimated";

import { SelectStyle } from "../utils/Styles/SelectStyleHome";

import { formStyles } from "../utils/Styles/FormStyleHome";
import { UseCpusFetch } from "../utils/api/CallCpusValues";
import { UseMemoryramFetch } from "../utils/api/CallMemoryramValues";
import { UseGpuFetch } from "../utils/api/CallGpuValues";
import { UseSsdFetch } from "../utils/api/CallSsdValues";
import { UseFonteFetch } from "../utils/api/CallFonteValues";
import { UseMotheboardsFetch } from "../utils/api/CallMotheboardsValues";
import { AlertTopHomeInfos } from "../utils/messages/AlertTopHome";

import ScriptAnalytcs from "../utils/ScriptGoogleAnalytcs";

export default function MyForm() {
  // Brand
  const BrandOptions = [
    { value: "", label: "" },
    { value: "AMD", label: "AMD" },
    { value: "INTEL", label: "INTEL" },
  ];
  const [brandSelected, setBrandSelected] = useState();
  const getBrandSelectedOPtion = brandSelected
    ? brandSelected.value
    : undefined;

  // CPU
  const [cpuSelectedId, setCpuId] = useState("0");
  const getCpuSelectedId = cpuSelectedId.value;
  const {
    data: cpus,
    loadingCpu,
    errorCpu,
  } = UseCpusFetch(getBrandSelectedOPtion);

  // Motheboard
  const [motheboardSelectedId, setMotheboardId] = useState("0");
  const getMotheboardSelectedId = motheboardSelectedId.value;
  const {
    data: motheboards,
    loadingMotheboard,
    errorMotheboard,
  } = UseMotheboardsFetch(getBrandSelectedOPtion, getCpuSelectedId);

  // Memoryram
  const [memoryselectedId, setMemoryId] = useState("0");
  const {
    data: Memoryram,
    loadingMemoryram,
    errorMemoryram,
  } = UseMemoryramFetch(getBrandSelectedOPtion, getMotheboardSelectedId);

  // SSD
  const [ssdselectedId, setSSDId] = useState("0");
  const {
    data: Ssd,
    loadingSsd,
    errorSsd,
  } = UseSsdFetch(getBrandSelectedOPtion, getMotheboardSelectedId);

  // GPU
  const [gpuselectedVoltage, setGPUVoltage] = useState("0");
  const getGpuSelectedVoltage = gpuselectedVoltage.voltage;
  const {
    data: Gpu,
    loadingGpu,
    errorGpu,
  } = UseGpuFetch(getBrandSelectedOPtion, getCpuSelectedId);

  // FONTE
  const [fonteSelected, setFonteSelected] = useState([]);
  const {
    data: Fonte,
    loadingFonte,
    errorFonte,
  } = UseFonteFetch(getGpuSelectedVoltage);

  // OTHERS
  const [isClicked, setIsClicked] = useState(false);
  const [isCompatible, setIsCompatible] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");

  // Get CpusAmd and set values on Select
  const [optionsCpu, setOptionsCpu] = useState([{ value: "", label: "" }]);
  useEffect(() => {
    if (!loadingCpu && cpus.length > 0) {
      const newOptions = cpus.map((cpu) => ({
        value: cpu.id,
        label: cpu.cpuname,
      }));
      setOptionsCpu([{ value: "", label: "" }, ...newOptions]);
    }
  }, [cpus, loadingCpu]);

  if (errorCpu) {
    console.error("ErrorCpu fetching data:", errorCpu);
  }

  // Get MotheboardAmd and set values on Select
  const [optionsMotheboard, setOptionsMotheboard] = useState([
    { value: "", label: "" },
  ]);
  useEffect(() => {
    if (!loadingMotheboard && motheboards.length > 0) {
      const newOptions = motheboards.map((motheboard) => ({
        value: motheboard.id,
        label: motheboard.motheboard,
      }));
      setOptionsMotheboard([{ value: "", label: "" }, ...newOptions]);
    }
  }, [motheboards, loadingMotheboard]);

  if (errorMotheboard) {
    console.error("ErrorMotheboard fetching data:", errorMotheboard);
  }

  // Get Memoryram and set values on Select (AMD/INTEL)
  const [optionsMemory, setOptionsmemoryram] = useState([
    { value: "", label: "" },
  ]);
  useEffect(() => {
    if (!loadingMemoryram && Memoryram.length > 0) {
      const newOptions = Memoryram.map((memoryram) => ({
        value: memoryram.memoryram_id,
        label: memoryram.memoryram_name,
      }));
      setOptionsmemoryram([{ value: "", label: "" }, ...newOptions]);
    }
  }, [Memoryram, loadingMemoryram]);

  if (errorMemoryram) {
    console.error("ErrorMemoryram fetching data:", errorMemoryram);
  }

  // Get Gpu and set values on Select (AMD/INTEL)
  const [optionsGPU, setOptionsGpu] = useState([{ value: "", label: "" }]);
  useEffect(() => {
    if (!loadingGpu && Gpu.length > 0) {
      const newOptions = Gpu.map((gpu_name) => ({
        value: gpu_name.id,
        label: gpu_name.gpu_name,
        voltage: gpu_name.gpu_voltage,
      }));
      setOptionsGpu([{ value: "", label: "" }, ...newOptions]);
    }
  }, [Gpu, loadingGpu]);

  if (errorGpu) {
    console.error("ErrorGpu fetching data:", errorGpu);
  }

  // Get Ssd and set values on Select (AMD/INTEL)
  const [optionsSSD, setOptionsSsd] = useState([{ value: "", label: "" }]);
  useEffect(() => {
    if (!loadingSsd && Ssd.length > 0) {
      const newOptions = Ssd.map((ssd_name) => ({
        value: ssd_name.ssd_name,
        label: ssd_name.ssd_name,
      }));
      setOptionsSsd([{ value: "", label: "" }, ...newOptions]);
    }
  }, [Ssd, loadingSsd]);

  if (errorSsd) {
    console.error("ErrorSsd fetching data:", errorSsd);
  }

  // Get Fonte and set values on Select (AMD/INTEL)
  const [optionsFonte, setOptionsFonte] = useState([{ value: "", label: "" }]);
  useEffect(() => {
    if (!loadingFonte && Fonte.length > 0) {
      const newOptions = Fonte.map((fonte) => ({
        value: fonte.power,
        label: fonte.namefonte,
      }));
      setOptionsFonte([{ value: "", label: "" }, ...newOptions]);
    }
  }, [Fonte, loadingFonte]);

  if (errorFonte) {
    console.error("ErrorSsd fetching data:", errorFonte);
  }

  function bottomconfirmCompatibility() {
    var iscompatible;

    if (
      getBrandSelectedOPtion !== "0" &&
      motheboardSelectedId !== "0" &&
      cpuSelectedId !== "0" &&
      memoryselectedId !== "0" &&
      ssdselectedId !== "0" &&
      gpuselectedVoltage !== "0"
    ) {
      iscompatible = true;
      setMessage("É compatível!");
      setMessage2(`Fonte recomendada: ${getGpuSelectedVoltage}W ou mais.`);
    } else if (
      getBrandSelectedOPtion !== "0" &&
      motheboardSelectedId !== "0" &&
      cpuSelectedId !== "0"
    ) {
      iscompatible = true;
      setMessage(
        "As opções selecionadas são compatíveis, mas você pode continuar verificando o restante."
      );
    } else {
      iscompatible = false;
      setMessage("Você precisa selecionar pelo menos as 3 primeiras opções.");
    }

    setIsCompatible(iscompatible);
    setIsClicked(true);
  }

  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <Scripts />
        <Navbar />
        <AlertTopHomeInfos />
        <div className="container-expand-lg containervar divform">
          <form
            className="formpricipal py text-sm placeholder:text-zinc-500"
            style={formStyles}>
            <div>
              <h2 className="form-floating h1form">
                Verificador de compatibilidade
              </h2>

              <label className="form-floating label">Marca</label>
              <Select
                options={BrandOptions}
                value={brandSelected}
                onChange={(selectedOption) => setBrandSelected(selectedOption)}
                placeholder="Selecione a marca"
                styles={SelectStyle}
              />

              <label className="form-floating label">Processador</label>
              <Select
                options={optionsCpu}
                value={cpuSelectedId}
                onChange={setCpuId}
                placeholder="Selecione o processador"
                styles={SelectStyle}
              />

              <label className="form-floating label">Placa-Mãe</label>
              <Select
                options={optionsMotheboard}
                Value={motheboardSelectedId.value}
                onChange={setMotheboardId}
                placeholder="Selecione a placa-mãe"
                styles={SelectStyle}
              />

              <label className="form-floating label">Memória Ram</label>
              <Select
                options={optionsMemory}
                value={memoryselectedId}
                onChange={setMemoryId}
                placeholder="Selecione a memoria ram"
                styles={SelectStyle}
              />

              <label className="form-floating label">SSD</label>
              <Select
                options={optionsSSD}
                value={ssdselectedId}
                onChange={setSSDId}
                placeholder="Selecione o ssd"
                styles={SelectStyle}
              />

              <label className="form-floating label">Placa de Vídeo</label>
              <Select
                options={optionsGPU}
                value={gpuselectedVoltage}
                onChange={setGPUVoltage}
                placeholder="Selecione a placa de vídeo"
                styles={SelectStyle}
              />

              <label className="form-floating label">Fonte</label>
              <Select
                options={optionsFonte}
                value={fonteSelected}
                onChange={setFonteSelected}
                placeholder="Selecione a fonte"
                styles={SelectStyle}
              />
              <br />
              <a
                className="btn btn-outline-light bottomconfirm"
                onClick={bottomconfirmCompatibility}>
                Verificar compatibilidade
              </a>
            </div>

            {isClicked && (
              <>
                <p
                  id="msg"
                  style={{
                    color: isCompatible ? "green" : "red",
                  }}>
                  {isCompatible ? <MdVerifiedUser /> : null}
                  {message} {message2}
                </p>
                <p
                  id="tips"
                  className="text-sm placeholder:text-zinc-500 text-white">
                  <strong>Dicas:</strong> Sempre opte por uma fonte de energia
                  com potência um pouco superior à recomendação da fabricante,
                  cerca de 100W a mais. Ao escolher uma GPU da linha RTX 4000,
                  verifique se a fonte de energia escolhida possui três entradas
                  de 8 pinos, antes de efetuar a compra nas lojas de
                  informaticas.
                </p>

                <p className="text-sm placeholder:text-zinc-500 text-white">
                  <strong>OBS:</strong> nem todas as placa-mãe são recomendadas
                  usar os processadores indicados, pois força demais a placa-mãe
                  e ela não usufrui do processador como deveria, além de algumas
                  versões precisarem de atualização na BIOS, apesar das
                  fabricantes indicarem compatibilidade. Em caso de duvidas
                  acesse o grupo do
                  <a
                    href="https://discord.gg/Me9cn5pyEQ"
                    target="_blank"
                    rel="noreferrer">
                    <strong>
                      <i> Discord</i>
                    </strong>
                  </a>
                </p>

                <h6 className="text-sm placeholder:text-zinc-500 text-white">
                  Faça uma doação e ajude o site a se manter clicando{" "}
                  <a
                    href="https://livepix.gg/iscompatible"
                    target="_blank"
                    rel="noreferrer">
                    aqui.
                  </a>
                </h6>
                <img src={Qrcode} className="pt-2 pb-2" alt="QRCode" />
              </>
            )}

            <div>
              <p id="tips" className="text-sm placeholder:text-zinc-500"></p>
            </div>
          </form>

          <Slider />

          <DonationsAnimated />

          <br />

          <Footer className="pb-4" />
        </div>
        <br /> <br />
      </div>
    </>
  );
}
